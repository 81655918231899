
@font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Black.ttf")
        format("truetype"),
      url("../fonts/Mont-Heavy.ttf")
        format("truetype"),
      url("../fonts/Mont-DEMOHeavy.ttf")
        format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-BlackItalic.ttf")
        format("truetype"),
      url("../fonts/Mont-HeavyItalic.ttf")
        format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont book";
    src: url("../fonts/Mont-Book.ttf")
      format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-BookItalic.ttf")
        format("truetype"),
      url("../fonts/Mont-RegularItalic.ttf")
        format("truetype"),
      url("../fonts/Mont-HairlineItalic.ttf")
        format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-ExtraLightItalic.ttf")
      format("truetype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-ExtraLight.ttf")
        format("truetype"),
      url("../fonts/Mont-DEMOExtraLight.ttf")
        format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Light.ttf")
      format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-LightItalic.ttf")
      format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-SemiBoldItalic.ttf")
      format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Thin.ttf")
      format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-SemiBold.ttf")
      format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-ThinItalic.ttf")
      format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Regular.ttf")
      format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Bold.ttf")
      format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-BoldItalic.ttf")
      format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Mont hairline";
    src: url("../fonts/Mont-Hairline.ttf")
      format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  