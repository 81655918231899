
body {
    font-family: 'Mont', sans-serif;
    padding-top: 96px;
    font-size: 16px;
    color: #0F2435;
}

a {
    text-decoration: none !important;
    color: #5865F2;
    transition: all 0.5s ease;
}

a:hover {
    color: #4954ce;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Mont;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.025em;
}

h1 {
    font-size: 60px;
    line-height: 72px;
}

h2 {
    font-size: 48px;
    line-height: 60px;
}

h3 {
    font-size: 36px;
    line-height: 48px;
}

h4 {
    font-size: 30px;
    line-height: 48px;
}

h5 {
    font-size: 24px;
    line-height: 36px;
}

h6 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
}

.text-grey {
    color: #55627D !important;
}

.text-grey2 {
    color: #667085 !important;
}

.text-grey3 {
    color: #4B5563 !important;
}

.text-green {
    color: #cd3752 !important;
}

.text-success {
    color: #5865F2!important;
}

.form-control {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    height: 56px;
    line-height: 56px;
    padding: 0 14px;
}

.form-control:focus {
    border-color: #5865F2;
    box-shadow: 0 0 0 0.2rem hsl(173deg 80% 40% / 25%);
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    margin-bottom: 6px;
}

textarea.form-control {
    resize: none;
}

.btn.btn-lg {
    padding: 17px 32px;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

::-webkit-input-placeholder {
    /* Edge */
    color: #667085;
}

 :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #667085;
}

 ::placeholder {
    color: #667085;
}


/***** header css start *****/

header {
    background: #fff;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: 96px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}

#logo img {
    width: 170px !important;
}
.logo-content img {
    max-width: 250px;
}

.navbar {
    padding: 5px 0px;
    height: inherit;
}
.navbar-collapse{
    flex-grow: 0;
}

.navbar-brand img {
    width: 150px;
    margin-right: 5px;
}

.navbar-light .navbar-nav .nav-link {
    color: #0F2435;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    text-stroke: 0.5px #5865F2;
    -webkit-text-stroke: 0.5px #5865F2;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    text-stroke: 0.5px #5865F2;
    -webkit-text-stroke: 0.5px #5865F2;
}

.navbar .nav-link {
    color: #0F2435;
    font-size: 16px;
    line-height: 24px;
    padding-right: 16px !important;
    padding-left: 16px !important;
    font-weight: 500;
}

.btn {
    font-family: Mont;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 17px 20px;
    display: inline-flex;
    align-items: center;
    box-shadow: none!important;
    border-radius: 30px !important;

}

.btn.btn-sm {
    padding: 11px 20px;
}

.btn img {
    position: relative;
    left: 0;
    transition: all 0.5s ease;
}

.btn:hover img {
    left: 5px;
}

.btn-primary {
    color: #fff;
    background-color: #5865F2!important;
    border-color: #5865F2!important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #4954ce!important;
    border-color: #4954ce!important;
}

.btn-secondary {
    color: #fff;
    background: #0F2435;
    border-color: #0F2435;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #119c8d;
    border-color: #119c8d
}

.btn-info {
    color: #5865F2;
    background: #fff;
    border-color: #FFFFFF;
}

.btn-info:hover {
    color: #fff;
    background-color: #119c8d;
    border-color: #119c8d;
}

.btn-link {
    padding: 11px 20px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 0;
}

.btn-link-primary {
    color: #5865F2;
    border-bottom: 1px solid #5865F2;
}

.btn-link-primary:hover {
    color: #119c8d;
}

.max-width-inherit {
    max-width: inherit !important;
}


/***** header css end *****/



.slick-dots li {
    margin: 0;
}
.slick-dots li button:before{
    font-size: 10px !important;
}
.slick-dots li button {
    width: 10px;
    height: 10px;
    background: #55627D;
    border-radius: 50%;
    opacity: 0.5;
}

.slick-dots li.slick-active button {
    opacity: 1;
}

.zigzag-wrapper {
    padding: 96px 0 0;
}

.zigzag-wrapper h2 {
    margin-bottom: 20px;
    font-weight: 600;
}

.zigzag-wrapper h3 {
    font-weight: 600;
}

.zigzag-wrapper .section-header p {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #55627D;
}

.zigzag-content h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 20px;
}
.customer-img {
    min-height: 400px;
    display: flex;
    align-items: center;
}

.zigzag-content p {
    font-size: 18px;
    line-height: 32px;
    color: #0F2435;
    margin-bottom: 20px;
}

.zigzag-image {
    border-radius: 8px;
    overflow: hidden;
}

.zigzag-image img {
    transition: all 0.5s ease;
    transform: scale(1);
}

.zigzag-image:hover img {
    transform: scale(1.1);
}


.dropdown-menu {
    margin-top: -1px;
    box-shadow: 0px 0px 26px 0px rgb(0 0 0 / 30%);
    padding: 10px 0 0px;
    border-color: transparent;
}

.navbar-category {
    margin-bottom: 20px;
}

.dropdown-header {
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    white-space: normal;
}

.dropdown-item {
    color: #6c757d;
    font-size: 0.875rem;
}

.dropdown-item:hover {
    background: transparent;
    color: #5865F2;
}

.card-block-img{
    position: relative;
    padding: 5px;
    border: 1px solid #ddd;
    z-index: 1;
    background: #fff;
}
.card-block-img-1 {
    position: relative;
    z-index: 0;
}
.sep-pad{
    padding-bottom: 150px;
}
.slider {
    padding: 0 15px;
    max-width: 1170px;
    margin: 0 auto;
}

.subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #5865f2;
    margin-bottom: 0;
}

.why-us-wrapper {
    padding: 0 0 60px;
}

.why-us-wrapper .title {
    margin-bottom: 32px;
}

.why-us-wrapper p {
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
}

.heighlights-wrapper {
    padding: 0 0 56px;
}

.heighlights-item h3 {
    line-height: 28px;
    color: #0F2435;
}

.heighlights-item h6 {
    font-size: 18px;
    line-height: 28px;
    color: #4B5563;
    margin-bottom: 0;
}

.some-people-wrapper {
    padding: 60px 0;
    background: #F8F8F8;
    position: relative;
}

.some-people-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 420px;
    background: #fff;
}

.some-people-wrapper .section-header p {
    max-width: 670px;
    font-size: 18px;
    line-height: 32px;
    margin: 0 auto;
}

.some-people-wrapper .title {
    margin-bottom: 16px;
}

.some-people-image img {
    width: 100%;
}

.some-people-item {
    background: #FFFFFF;
    box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%), 0px 10px 10px -5px rgb(0 0 0 / 4%);
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #F3F4F6;
    display: block;
}

.some-people-info {
    padding: 16px;
    background: #fff;
    position: relative;
}

.some-people-title {
    font-size: 18px;
    line-height: 24px;
    color: #0F2435;
    margin-bottom: 4px;
}

.some-people-desc {
    font-size: 18px;
    line-height: 24px;
    color: #5865F2;
    margin-bottom: 16px;
}

.some-people-item p {
    font-size: 16px;
    line-height: 24px;
    color: #55627D;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
}
.customer-says-wrapper {
    background: #F8F8F8;
    padding: 20px 0 60px;
}

.customer-says-inner {
    margin: 0 auto;
    overflow: hidden;
}

.customer-says-item {
    background: #fff;
    padding: 45px 18px;
    text-align: center;
    margin: 0 16px 40px;
}

.customer-says-item.slick-current {
    background: #FFFFFF;
    box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%), 0px 10px 10px -5px rgb(0 0 0 / 4%);
    border-radius: 8px;
}

.customer-says-image {
    width: 90px;
    position: relative;
    margin: 0 auto 28px;
}

.customer-says-image:after {
    /* content: url("../../assets/images/icon-qoute-green.svg"); */
    position: absolute;
    right: 0;
    top: 0;
}

.customer-says-text {
    margin-bottom: 22px;
    font-size: 18px;
    line-height: 28px;
    color: #111827;
}

.customer-says-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 3px;
}

.customer-says-desc {
    font-size: 16px;
    line-height: 26px;
    color: #4B5563;
}

.customer-says-wrapper .title {
    font-weight: 600;
    margin-bottom: 20px;
}

.customer-says-wrapper .section-header p {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #0F2435;
    max-width: 670px;
    margin: 0 auto;
}


.service-banner-wrapper {
    padding: 0 0 60px;
}

.service-wrapper {
    padding-bottom: 66px;
}

.service-wrapper.service-detail-wrapper {
    padding-bottom: 0;
}

.service-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.service-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/images/service-shadow.png");
    z-index: 1;
}

.service-img {
    margin: 0;
}

.service-img img {
    width: 100%;
    height: auto;
    max-width: inherit;
    transition: all 0.5s ease;
    transform: scale(1);
}

.service-item:hover img {
    transform: scale(1.1);
}

.service-info {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 30px;
    color: #fff;
    z-index: 2;
    background: linear-gradient(45deg, #000000ab, transparent);
}

.service-title {
    color: #fff;
}

.service-item p {
    margin-bottom: 0;
}


.request-free-analysis-wrapper {
    padding: 60px 0;
    background: #F8F8F8;
}

.request-free-analysis-text {
    font-size: 18px;
    line-height: 32px;
    color: #0F2435;
    margin-bottom: 32px;
}

.theme-list {
    list-style-type: none;
    padding-left: 0;
}

.theme-list li {
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    line-height: 28px;
    color: #0F2435;
}

.theme-list li:not(:last-of-type) {
    margin-bottom: 16px;
}

.theme-list li:before {
    content: "";
    min-width: 20px;
    height: 25px;
    background: url( ../../assets/images/icon-checkmark-round.svg) no-repeat;
    display: inline-block;
    margin-right: 20px;
    background-position: center;
    position: relative;
    top: 3px;
}


.home-technologies {
    padding: 100px 0;
    background: rgb(88 101 242 / 10%);
    text-align: center
}

.home-technologies h2 {
    padding-bottom: 40px
}

.home-technologies p {
    padding-bottom: 44px
}

.home-technologies a:focus {
    outline: 0
}

.home-technologies .nav-tabs {
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    max-width: 1070px;
    margin: 0 auto
}

.home-technologies .nav-tabs .nav-link {
    border: 0;
    padding: 16px 0;
    margin: 0 30px
}

.home-technologies .nav-tabs .nav-item {
    position: relative
}

.home-technologies .nav-tabs .nav-link:focus {
    outline: 0
}

.home-technologies ul li a {
    font-size: 24px;
    line-height: 170%;
    color: #181815!important;
    letter-spacing: -.01em;
    font-weight: 600
}

.home-technologies .nav-tabs .nav-item.show .nav-link,.home-technologies .nav-tabs .nav-link.active {
    color: #5865F2!important
}

.home-technologies .nav-tabs .nav-item.show .nav-link,.home-technologies .nav-tabs .nav-link.active {
    background-color: transparent
}

.home-technologies .nav-tabs .nav-link.active:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: currentColor
}

.tech-wrapper {
    padding-top: 10px
}

.tech-wrapper p {
    padding-bottom: 0
}

.tech-wrapper .row {
    justify-content: center
}

.tech-wrapper img {
    margin-bottom: 30px;
    height: 66px
}

.tech-wrapper a {
    padding: 30px 15px;
    background-color: transparent;
    display: block;
    margin-top: 30px;
    transition: ease-in-out .4s
}

.tech-wrapper a:hover {
    background-color: #fff;
    box-shadow: 0 20px 100px rgb(0 0 0/10%)
}

.tech-wrapper a:focus {
    outline: 0
}

.tech-wrapper p {
    line-height: 1.6;
    font-size: 18px;
    padding-bottom: 0!important;
    font-weight: 600;
    color: #000;
}

.h-300{
    height: 300px;
}