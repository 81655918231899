html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: sans-serif;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
sec,
summary {
    display: block;
}

a {
    background-color: rgba(0, 0, 0, 0);
}

b,
strong {
    font-weight: bold;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1 {
    margin: 0.67em 0;
    font-size: 2em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"] {
    -webkit-appearance: button;
    cursor: pointer;
}

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    color: #333;
    background-color: #fff;
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.d-inline-block {
    max-width: 100%;
    display: inline-block;
}

.d-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 10px;
    font-weight: bold;
}

h1 {
    margin-top: 20px;
    font-size: 38px;
    line-height: 44px;
}

h2 {
    margin-top: 20px;
    font-size: 32px;
    line-height: 36px;
}

h3 {
    margin-top: 20px;
    font-size: 24px;
    line-height: 30px;
}

h4 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 24px;
}

h5 {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
}

h6 {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
}

button,
[type="button"],
[type="reset"] {
    cursor: pointer;
    -webkit-appearance: button;
    border: 0;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Mont, sans-serif;
    font-weight: 700;
    line-height: 24px;
}

h5 {
    color: #0a0a0a;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Mont, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    position: static;
}

h6 {
    color: #5865F2;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Mont, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

p {
    margin-bottom: 10px;
    font-family: Mont, sans-serif;
    font-size: 14px;
    line-height: 24px;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
    font-family: Mont, sans-serif;
    font-size: 14px;
}

.navbar {
    z-index: 999;
    width: 100%;
    height: auto;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 23px;
    padding-bottom: 23px;
    display: flex;
}

.header-button {
    z-index: 999;
    grid-column-gap: 6px;
    background-color: #5865F2;
    color: #fff !important;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    padding: 13px 25px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 30px;
}

.header-button {
    margin-left: 32px;
}

.header-button:hover {
    background-color: #4954ce!important;
    border-color: #4954ce!important;
}

.body-text-bold {
    color: #fff;
    font-family: Mont, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
}

.body-text-bold.dsv {
    float: none;
    color: #f6f6fd;
    text-transform: uppercase;
    font-weight: 700;
}

.body-text-bold.dsv.black {
    width: 100%;
    color: #0a0a0a;
    background-color: #fff;
    border-radius: 30px;
    padding: 13px 25px;
    transition: all 0.2s;
}

.body-text-bold.dsv.black:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
}

.body-text {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: #2a1c3c;
    white-space: normal;
    -webkit-text-fill-color: inherit;
    mix-blend-mode: normal;
    object-fit: scale-down;
    background-clip: border-box;
    flex-direction: column;
    padding: 0;
    font-family: Mont, sans-serif;
    font-size: 14px;
    line-height: 30px;
    text-decoration: none;
    transition: all 0.2s;
    display: flex;
    position: static;
    overflow: hidden;
}

.body-text.centered {
    text-align: center;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
}

.body-text.white {
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    overflow: visible;
}

.body-text.white.footer-hover {
    color: #f6f6fd;
    text-transform: capitalize;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
}

.body-text.white.footer-hover.email-text-footer {
    text-transform: lowercase;
}

.sec {
    width: auto;
    flex-direction: column;
    margin: 0 auto 60px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    overflow: hidden;
}

.sec.m-120 {
    width: auto;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.sec.m-120.m-60 {
    margin-top: 0;
    overflow: visible;
}

.sec.ma-120 {
    width: auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 51px;
    padding-bottom: 35px;
    padding-left: 40px;
    display: block;
}

.sec.color {
    background-color: rgba(86, 27, 201, 0.05);
    margin-top: 0;
}

.sec.mar-above-big {
    margin-top: 30px;
}

.wrapper-title-subtitle {
    margin-top: 10px;
}

.wrapper-title-subtitle {
    width: 809px;
    grid-row-gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 19px auto 0;
    display: flex;
}

.h1 {
    color: #0a0a0a;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Mont, sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
}

.h1.dsv {
    color: #2a1c3c;
    text-transform: uppercase;
}

.button.m-40 {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

.button.centered {
    text-align: center;
}

.button.centered.m-40 {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    display: flex;
}

.button.centered.m-40.quick-enquiry {
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
}

.btn-site {
    background-color: #5865F2;
    border: 0 solid #000;
    border-radius: 30px;
    padding: 2px;
    text-decoration: none;
    transition: all 0.2s;
}

.btn-site.developement {
    background-color: #5865F2;
    margin-top: 0;
    display: block;
}

.btn-site.developement {
    background-color: #5865F2;
}

.hero-image {
    width: 550px;
}

.image {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.wrapper-title {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
}

.new-container .wrapper-title {
    margin-bottom: 40px;
}

.wrapper-title.max-d-100 {
    margin-bottom: 50px;
    margin-left: 0;
    margin-right: 0;
}

.h2 {
    color: #0a0a0a;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Mont, sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 52px;
}

.h2.centered {
    text-align: center;
}

.h2.centered.dsv {
    color: #2a1c3c;
    text-transform: uppercase;
    margin: 0 auto;
    padding-top: 0;
}

.h2.centered.dsv.max-d-100 {
    margin: 0;
    padding-top: 0;
}

.h2.dsv {
    text-transform: uppercase;
}

.btn-site:hover {
    background-color: #5865F2;
    border: 0 solid #000;
    border-radius: 30px;
    padding: 2px;
    text-decoration: none;
    transition: all 0.2s;
}

.btn-site {
    background-color: #5865F2;
    margin-top: 0;
    display: block;
}

.btn-site.color-light-purple {
    background-color: #f5f1fc;
}

.btn-site.hide-it {
    display: none;
}

.btn-site.display-horizontal {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    background-color: #5865F2;
    background-image: none;
    justify-content: center;
    align-items: center;
    padding-left: 33px;
    padding-right: 33px;
    display: flex;
}

.btn-site.webinar-button {
    text-align: center;
    background-color: #5865F2;
}

.btn-site {
    background-color: #5865F2;
}

.btn-site.bigger {
    width: 300px;
    display: block;
}

.body-text-bold.dsv.black:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
}

.wrapper-logo.m-80 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

.wrapper-logo.m-80.allgn_center {
    grid-column-gap: 22px;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.wrapper-cards-dishivi.m-40 {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

.text-block {
    color: #0a0a0a;
    text-transform: uppercase;
    font-family: Mont, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
}

.wrapper-serve.m-40 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 40px;
    display: grid;
}

.serve-block {
    border: 1px solid rgba(154, 77, 172, 0.25);
    border-radius: 8px;
    padding: 64px 24px;
}

.title-sub {
    grid-row-gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
}

.text-block-2 {
    color: #2a1c3c;
    text-transform: uppercase;
    font-family: Mont, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
}

.footer {
    background-color: #000000;
}

.footer.m-120 {
    width: 100%;
    margin-top: 0;
    padding-top: 35px;
    padding-bottom: 24px;
}

.footer-menu {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: left;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: 0.4fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    justify-items: end;
}

.social.m-48 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 10px;
    display: flex;
}

.logo-text-social.width-264 {
    padding-right: 50px;
}

.footer-link-block {
    margin-left: 0;
}

.footer-link-block.width-280 {
    width: 280px;
}

.footer-link-block.width-280.m-32 {
    width: auto;
}

.submenu.m-16 {
    grid-row-gap: 8px;
    flex-direction: column;
    margin-top: 16px;
    display: flex;
}

.submenu.m-16.width-258 {
    width: auto;
    clear: none;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.divider.m-95 {
    width: 1336px;
    height: 1px;
    background-color: #fff;
    margin-top: 95px;
    margin-left: auto;
    margin-right: auto;
}

.divider.m-95.divider-footer-div {
    width: auto;
    background-color: #4c3a6d;
    margin-top: 20px;
    margin-bottom: 20px;
}

.divider.m-95 {
    width: 1336px;
    height: 1px;
    background-color: #fff;
    margin-top: 95px;
    margin-left: auto;
    margin-right: auto;
}

.other-info {
    width: 1336px;
    margin-left: auto;
    margin-right: auto;
}

.other-info.m-48 {
    width: auto;
    margin-top: 48px;
}

.disck {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
}

.captions {
    color: #fff;
    margin-left: 0;
    font-family: Mont, sans-serif;
    font-weight: 400;
}

.captions.hover-caption {
    transition: opacity 0.2s;
}

.captions.hover-caption:hover {
    text-decoration: underline;
}

.subline {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    display: flex;
}

.title-sub {
    grid-row-gap: 16px;
    flex-direction: column;
    display: flex;
}

.wrapper-full {
    background-color: rgba(0, 0, 0, 0);
    margin-top: 0;
}

.button-footer-ds.m-32 {
    margin-top: 32px;
}

.button-fooder-ds {
    color: #fff;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #fff;
    border-radius: 8px;
    padding: 16px 75px 12px;
    font-family: Mont, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.button-fooder-ds.new {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    transition: all 0.2s;
    display: flex;
}

.all-sec {
    margin-left: auto;
    margin-right: auto;
}

.divider {
    width: 1280px;
    height: 1px;
    background-color: rgba(154, 77, 172, 0.25);
    margin-left: auto;
    margin-right: auto;
}

.social-link {
    padding-left: 5px;
    padding-right: 5px;
}

.with-dishivi-you-can {
    width: auto;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
    padding-bottom: 50px;
}

.text-span-47,
.text-span-48 {
    color: #5865F2;
}

.text-span-49,
.text-span-50,
.text-span-51 {
    color: #562bc6;
}

.bold-text-7,
.bold-text-8,
.bold-text-9 {
    font-weight: 400;
}

.new-container {
    width: auto;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    display: block;
    overflow: visible;
}

.new-container.color {
    background-color: rgba(0, 0, 0, 0);
}

.div-block-54 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.hero-sec {
    width: auto;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    overflow: visible;
}

.location-icon {
    width: 20px;
    margin-right: 5px;
}

.location-div {
    align-items: center;
    display: flex;
}

.text-block-25 {
    color: #fff;
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 700;
}

.link-location {
    color: #fff;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}

.link-location:hover {
    text-decoration: underline;
}

.location-inside-div {
    align-items: center;
    display: flex;
    margin-right: 5px;
}

.header {
    background-color: #fff;
    padding: 16px;
}

.image {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.link_location {
    display: flex;
}

.footer-icon {
    font-size: 20px;
    color: #fff !important;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.social-icon {
    font-size: 36px;
}

.section.mar-big {
    margin-top: 100px;
    margin-bottom: 100px;
}

.address-wrapper.m-40 {
    grid-column-gap: 32px;
    grid-row-gap: 52px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    margin-top: 40px;
    display: grid;
}
.address-card {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    grid-area: span 1 / span 1 / span 1 / span 1;
    padding: 20px 15px;
    border-radius: 10px;
}
.address-card:hover {
    background: #5865F2;
    color: #fff !important;
}

.address-card:hover *{
    color: #fff !important;
}
.address-maps-link {
    text-decoration: none;
    color: #000 !important;
}

.icon-title-contact {
    grid-column-gap: 4px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: min-content 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.contact-icon {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 22px;
    margin-right: 5px;
}

.h3.dsv.letter-capital.cityname {
    font-size: 18px;
    color: #000;
    margin: 0;
}

.h3.dsv.letter-capital {
    text-transform: uppercase;
}

.address-wrapper-contact.m-16.m-29 {
    margin-left: 25px;
}

.address-wrapper-contact.m-16 {
    margin-top: 16px;
}

.body-text.con-address {
    height: 96px;
}

.button-fooder-ds.new:hover {
    color: #0a0a0a;
    background-color: #fff;
}

.sec.color {
    background-color: rgb(88 101 242 / 10%);
    margin-top: 0;
}

.contact-wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.contact-info {
    width: auto;
}

.h2.dsv {
    text-transform: uppercase;
}

.desc-contact.m-24 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
    margin-top: 24px;
    display: flex;
}

.desc-3-contact.m-40 {
    margin-top: 40px;
}

.icon-social.m-24 {
    grid-column-gap: 32px;
    margin-top: 24px;
    display: flex;
}

.section.mar-above {
    margin-top: 20px;
}

.breadcrumbs {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.breadcrumbs.m-40 {
    width: auto;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
}

.breadcrumbs .fa {
    font-size: 10px;
    position: relative;
    top: 3px;
}

.link {
    color: #0a0a0a;
    font-family: Mont, sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
}

.link.bold {
    color: #5865F2;
    font-weight: 600;
}

.main-menu-col {
    display: block;
}

@media screen and (min-width: 1440px) {
    .body-text {
        text-align: left;
        text-transform: none;
        font-size: 16px;
        line-height: 32px;
    }
}

@media screen and (max-width: 991px) {
    .address-wrapper.m-40 {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
    }
    .wrapper-title-subtitle {
        width: 100%;
    }
    .wrapper-cards-dishivi.m-40 {
        grid-template-columns: 1fr;
        justify-items: start;
    }
    .wrapper-serve.m-40 {
        grid-template-columns: 1fr 1fr;
    }
    .footer-menu {
        width: 100%;
        grid-row-gap: 52px;
        flex-direction: column;
        grid-template-columns: 2fr;
    }
    .logo-text-social.width-264 {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
    .button-footer-ds.m-32 {
        width: 50%;
    }
    .image-19 {
        /* height: 100px; */
    }
    .location-div {
        grid-column-gap: 0px;
        grid-row-gap: 16px;
        flex-direction: column;
        align-items: flex-start;
    }
    .location-inside-div {
        flex-wrap: wrap;
    }
    .disck {
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .contact-wrapper {
        grid-template-columns: 1fr;
    }
    .address-wrapper.m-40 {
        grid-template-columns: 1fr;
    }
    .body-text.con-address {
        height: auto;
    }
}

@media screen and (max-width: 479px) {
    .bold-text.max-d-100 {
        max-width: 100%;
        object-fit: fill;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    h2.centered.dsv {
        white-space: normal;
        object-fit: fill;
        font-size: 24px;
        line-height: 32px;
    }
    .wrapper-logo.m-80.allgn_center {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        flex-flow: wrap;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-columns: 1fr;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        display: flex;
    }
    .wrapper-cards-dishivi.m-40 {
        grid-row-gap: 48px;
    }
    .with-dishivi-you-can {
        text-align: center;
    }
    .wrapper-serve.m-40 {
        grid-column-gap: 0px;
        grid-row-gap: 32px;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: stretch;
        margin-top: 24px;
    }
    .sec.ma-120 {
        margin-top: 0;
        margin-bottom: 40px;
        padding: 0 40px 0 20px;
    }
    .sec {
        width: 100%;
        padding: 0 30px;
    }
    .hero-section {
        padding-left: 0;
        padding-right: 5px;
        position: static;
        overflow: visible;
    }
    .h1.dsv {
        width: 100%;
        font-size: 24px;
        line-height: 32px;
    }
    .wrapper-tiitle-subtittle._w-809 {
        grid-row-gap: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .body-text.centered {
        margin-top: 0;
    }
    .button.centered.m-40 {
        width: 80%;
        margin-top: 32px;
        padding-left: 0;
        padding-right: 0;
    }
    .main-button._100 {
        width: 100%;
        margin-top: 0;
    }
    .hero-image {
        width: 250px;
    }
    .image {
        margin-top: 40px;
    }
}

.customer-says-wrapper .slick-track {
    width: 100%!important;
}