@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1216px;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .navbar-nav .btn:not(:last-of-type) {
    margin-right: 10px;
  }
  .navbar .nav-link {
    font-size: 14px;
  }
  .wrapper-serve.m-40 {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1024px) {
  .header-button {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .banner-img.banner-img-1 {
    position: static;
    bottom: -100px;
    left: 0;
    z-index: 0;
    transform: scale(0.9);
    z-index: 2;
  }
  .sep-pad-mob {
    padding-bottom: 150px;
  }

  .banner-inner {
    padding-bottom: 50px;
  }

  .navbar-collapse {
    padding-top: 20px;
    position: fixed;
    background: #fff;
    width: 100vw;
    left: -100%;
    padding: 20px;
    top: 96px;
    height: calc(100vh - 66px) !important;
    transition: all 0.2s ease;
    overflow: auto;
    border-top: 1px solid #ddd;
  }
  .navbar-collapse.show {
    left: 0;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none;
    outline: 0;
  }
  .navbar-collapse {
    padding-top: 20px;
  }
  .navbar-nav .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:hover,
  .dropdown-item:focus {
    /* color: #2a089f !important; */
    text-decoration: none;
    background-color: #fff !important;
  }
  .dropdown-header,
  .dropdown-item {
    padding: 0px 10px;
    font-size: 14px;
    white-space: normal;
    line-height: normal;
    margin: 5px 0;
  }
  .dropdown-header {
    margin-bottom: 10px;
  }
  .dropdown > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
    transition: all 0.5s ease;
  }

  .navbar-dark .navbar-toggler {
    padding: 0;
    border: 0;
    outline: none;
  }
  .navbar-toggler-icon {
    background: #5865F2 url(src/assets/images/icon-hamburger.svg) !important;
    background-size: contain !important;
    background-position: center !important;
    /* position: absolute;
        top: 23px;
        right: 0; */
    width: 40px;
    height: 40px;
    border: 9px solid transparent;
    border-radius: 50%;
    background-repeat: no-repeat !important;
  }
  .banner-info {
    margin-top: 30px;
  }
  .banner-wrapper {
    background: #5865f20f;
  }
  .banner-wrapper .banner-inner:after {
    transform: none;
  }
  .footer-inner-row > div {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .company-footer {
    align-items: flex-start;
  }
  .copyright-right {
    justify-content: space-between;
  }
  .zigzag-image {
    margin-left: 0 !important;
  }
  .zigzag-body .row > div {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .home-technologies ul li a {
    font-size: 18px;
  }
  .div-block-54 {
    /* grid-template-columns: 1fr; */
  }
  .footer-links a,
  .copyright-text {
    font-size: 14px;
  }
  .footer-links a {
    line-height: 30px;
  }
  .copyright-wrapper {
    padding: 30px 0;
  }
  .contact-icon {
    min-width: 24px;
  }

  .zigzag-content h4 {
    line-height: 34px;
    font-size: 26px;
  }
  .btn {
    padding: 11px 20px;
  }

  .banner-wrapper p {
    font-size: 16px;
    line-height: 24px;
  }
  .zigzag-content p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .subtitle {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px !important;
    margin: 0 0 10px;
  }
  h3 {
    font-size: 24px;
    line-height: normal !important;
  }
  h1,
  .banner-title {
    font-size: 36px;
    line-height: normal;
    margin-bottom: 10px;
  }

  .copyright-right {
    display: block;
  }
  .header {
    padding: 0;
  }
  .navbar-light .navbar-toggler {
    padding: 0;
  }
}

@media (max-width: 567px) {
  .footer-inner-row > div {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
